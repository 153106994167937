import logo from './logo.svg';
import './App.css';
import React from 'react';
import * as XLSX from 'xlsx';

var TempDate = new Date(), i, AFRDArray = new Array(12), sheet, QTRPeriod;
var ThisYear = TempDate.getFullYear(), ThisMonth, ThisDay, MonthEnd;
ThisMonth = TempDate.getMonth();
ThisDay = TempDate.getDate() - 1;
let x = document.getElementById("ID_Year");

for (i=x.options.length-1; i !== -1; i--) {
    x.remove(x.options(i));
}

for (i = ThisYear; i >= 2020; i--) {
   let x = document.getElementById("ID_Year");
   let MyOption = document.createElement("option");
   MyOption.text = i;
   MyOption.value = i;
   x.add(MyOption);
}
document.getElementById('ID_Year').selectedIndex = 0;
document.getElementById('ID_Month').selectedIndex = ThisMonth;
document.getElementById('ID_Day').selectedIndex = ThisDay;  

function App() {
  document.getElementById('ID_Year').addEventListener("change", DateOptions);
  document.getElementById('ID_Day').addEventListener("change", DateOptions);
  document.getElementById('ID_Month').addEventListener("change", DateOptions);
  document.getElementById('openXLSX').addEventListener("click", loadExcel);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          vimskovlimbo@gmail.com
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          AFRD XML conversion
        </a>
      </header>
    </div>
  );
}

export default App;

function DateOptions() {
   ThisYear = document.getElementById('ID_Year').value;
   ThisMonth = document.getElementById('ID_Month').value;

   switch(document.getElementById('ID_Month').selectedIndex) {
   case 0: 
      {MonthEnd = 31; QTRPeriod=1; break;}
   case 1: 
      var TempoDate = new Date(ThisYear, 1, 29);
      if (TempoDate.getMonth() === 4)
        {MonthEnd = 29; QTRPeriod=1; break;}
      else
        {MonthEnd = 28; QTRPeriod=1; break;}
   case 2: 
      {MonthEnd = 31; QTRPeriod=1; break;}
   case 3:
     {MonthEnd = 30; QTRPeriod=2; break;}
   case 4:
     {MonthEnd = 31; QTRPeriod=2; break;}
   case 5:
     {MonthEnd = 30; QTRPeriod=2; break;}
   case 6:
     {MonthEnd = 31; QTRPeriod=3; break;}
   case 7:
     {MonthEnd = 31; QTRPeriod=3; break;}
   case 8:
     {MonthEnd = 30; QTRPeriod=3; break;}
   case 9:
     {MonthEnd = 31; QTRPeriod=4; break;}
   case 10:
     {MonthEnd = 30; QTRPeriod=4; break;}
   case 11:
     {MonthEnd = 31; QTRPeriod=4; break;}
   default :
     {MonthEnd = 31;
     break;}
   }
   if (document.getElementById('ID_Day').selectedIndex + 1 > MonthEnd) document.getElementById('ID_Day').selectedIndex = MonthEnd - 1;
   TempDate = new Date(ThisYear, document.getElementById('ID_Month').selectedIndex, document.getElementById('ID_Day').selectedIndex);
}

function loadExcel() {
  const fileInput = document.getElementById('fileInput');
  const outputDiv = document.getElementById('output');

  const file = fileInput.files[0];
  const reader = new FileReader();
  var data, workbook, sheetName, xmlText;

  reader.onload = function (e) {
     data = new Uint8Array(e.target.result);
     workbook = XLSX.read(data, { type: 'array' });
    // Assume the first sheet is being used
    //cellValue = sheet['A1'].v;
    // Convert sheet data to HTML table
    //htmlTable = XLSX.utils.sheet_to_html(sheet);
    AFRDArray[0] = AFRD00();
    var ArrLen = workbook.SheetNames.length;
    sheetName = workbook.SheetNames[0];
    sheet = workbook.Sheets[sheetName];
    for (i=0; i < ArrLen; i++) {
       sheetName = workbook.SheetNames[i];
       sheet = workbook.Sheets[sheetName];
       switch(sheetName) {
          case "AFRD_MRA":
             AFRDArray[1] = AFRD01();
             break;
          case "AFRD_A":
             AFRDArray[2] = AFRD02();
             break;
          case "AFRD_A1":
             AFRDArray[3] = AFRD03();
             break;
          case "AFRD_A2":
             AFRDArray[4] = AFRD04();
             break;
          case "AFRD_B":
             AFRDArray[5] = AFRD05();
             break;
          case "AFRD_B1":
             AFRDArray[6] = AFRD06();
             break;
          case "AFRD_B2":
             AFRDArray[7] = AFRD07();
             break;
          case "AFRD_B3":
             AFRDArray[8] = AFRD08();
             break;
          case "AFRD_C":
             AFRDArray[9] = AFRD09();
             break;
          case "AFRD_C1":
             AFRDArray[10] = AFRD10();
             break;
          case "AFRD_D":
             AFRDArray[11] = AFRD11();
             break;
          default:
          // code block
       } 
    }
    // Display the HTML table
    ArrLen = AFRDArray.length;
    xmlText = '<?xml version="1.0" encoding="utf-8"?>\r\n';
    xmlText = xmlText + '<AFRD xmlns="http://bsp.gov.ph/xml/AFRD/1.0">\r\n';
    for (i = 0; i < ArrLen; i++) {
       if (AFRDArray[i] != null) {
          xmlText = xmlText + AFRDArray[i] + "\r\n";
       }
    }
    xmlText = xmlText + '</AFRD>';
    outputDiv.value = xmlText;
  }
  reader.readAsArrayBuffer(file);
}

function AFRD00() {
   let tempText;
   tempText = "\t<Header>\r\n";
   tempText = tempText + "\t\t<Undertaking>" + document.getElementById('CorpBSPCode').value + "</Undertaking>\r\n";
   tempText = tempText + "\t\t<Year>" + ThisYear + "</Year>\r\n";
   tempText = tempText + "\t\t<Period>" + parseInt(QTRPeriod) + "</Period>\r\n";
   tempText = tempText + "\t</Header>";
   return tempText;
}

function AFRD01() {
   var tempText, j, k;
   j = 10;
   k = 42;
   tempText = "\t" + "<AFRD_MRA>" + "\r\n";
   tempText = tempText + "\t\t" + "<MAIN>" + "\r\n";
   while (j <= k) {
      if (j === 11) {j++; continue;}
      if (j === 15) {j++; continue;}
      if (j === 17) {j++; continue;}
      if (j === 18) {j++; continue;}
      if (j === 25) {j++; continue;}
      if (j === 35) {j++; continue;}
      if (j === 40) {j++; continue;}
      if (j === 41) {j++; continue;}
      if (sheet['D'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["D8"].v + ">";
         tempText = tempText + (sheet['D'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["D8"].v + ">\r\n";
      }
      if (sheet['E'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["E8"].v + ">";
         tempText = tempText + (sheet['E'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["E8"].v + ">\r\n";
      }
      if (sheet['F'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["F8"].v + ">";
         tempText = tempText + (sheet['F'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["F8"].v + ">\r\n";
      }
      j++;
   }
   tempText = tempText + "\t\t" + "</MAIN>" + "\r\n";
   tempText = tempText + "\t" + "</AFRD_MRA>";
   return tempText;
}

function AFRD02() {
   var tempText, j, k;
   j = 11;
   k = 16;
   tempText = "\t" + '<AFRD_A>' + "\r\n";
   tempText = tempText + "\t\t" + '<MAIN_A>' + "\r\n";
   while (j <= k) {
      if (j === 12) {j++; continue;}
      if (sheet['D'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["D10"].v + ">";
         tempText = tempText + (sheet['D'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["D10"].v + ">\r\n";
      }
      if (sheet['F'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["F10"].v + ">";
         tempText = tempText + (sheet['F'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["F10"].v + ">\r\n";
      }
      j++;
   }
   tempText = tempText + "\t\t" + '</MAIN_A>' + "\r\n";
   tempText = tempText + "\t" + '</AFRD_A>';
   return tempText;
}

function AFRD03() {
   var tempText, j, k;
   j = 12;
   k = 21; 
   tempText = "\t" + '<AFRD_A1>' + "\r\n";
   tempText = tempText + "\t\t" + '<MAIN>' + "\r\n";
   while (j <= k) {
      if (sheet['D'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["D9"].v + ">";
         tempText = tempText + sheet['D'+j].v;
         tempText = tempText + "</" + sheet['C'+j].v + sheet["D9"].v + ">\r\n";
      }
      if (sheet['E'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["E9"].v + ">";
         tempText = tempText + (sheet['E'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["E9"].v + ">\r\n";
      }
      if (sheet['F'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["F9"].v + ">";
         tempText = tempText + sheet['F'+j].v;
         tempText = tempText + "</" + sheet['C'+j].v + sheet["F9"].v + ">\r\n";
      }
      if (sheet['G'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["G9"].v + ">";
         tempText = tempText + (sheet['G'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["G9"].v + ">\r\n";
      }
      j++;
   }
   tempText = tempText + "\t\t" + '</MAIN>' + "\r\n";
   tempText = tempText + "\t" + '</AFRD_A1>';
   return tempText;
}
function AFRD04() {
   var tempText, j, EndHere = 0;
   tempText = "\t" + '<AFRD_A2>' + "\r\n";
   j = 20;
   while (EndHere < 10) {
      if (sheet['A'+j] === undefined) {
         j++;
         EndHere = EndHere + 1;
      }
      else {
         EndHere = 0;
         switch(sheet['A'+j].v) {
            case "A. Total Wholesale Loans Granted to RFIs":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y1>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y1_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y1_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y1>' + "\r\n";
               break;
            case "B.  Total Loans Extended under Rediscounting Facility 2/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y2>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y2_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y2_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y2>' + "\r\n";
               break;
            case "C. Total Infrastructure/Construction Loans 3/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y3>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y3_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y3_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y3>' + "\r\n";
               break;
            case "D. Total Agri-business Enterprise Loans 4/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y4>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y4_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y4_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y4>' + "\r\n";
               break;
            case "E.Total Agricultural Value Chain Financing (AVCF) 5/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y5>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y5_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y5_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y5>' + "\r\n";
               break;
            case "F. Total Loans to Finance Sustainable Projects or Activities 6/ 7/ ":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y6>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  if (typeof sheet['E'+j].v !== 'number') {j++; continue;}
                  tempText = tempText + "\t\t\t" + '<MAIN_Y6_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y6_Item>' + "\r\n";
                  j++;  
               }
               tempText = tempText + "\t\t" + '</MAIN_Y6>' + "\r\n";
               break;
            default:
            // code block
         }
      }
   }
   tempText = tempText + "\t" + '</AFRD_A2>';
   return tempText;
}

function AFRD05() {
   var tempText, j, k;
   j = 10;
   k = 15;
   tempText = "\t" + '<AFRD_B>' + "\r\n";
   tempText = tempText + "\t\t" + '<MAIN>' + "\r\n";
   while (j <= k) {
      if (j === 11) {j++; continue;}
      if (sheet['D'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["D9"].v + ">";
         tempText = tempText + (sheet['D'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["D9"].v + ">\r\n";
      }
      if (sheet['E'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["E9"].v + ">";
         tempText = tempText + (sheet['E'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["E9"].v + ">\r\n";
      }
      if (sheet['F'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["F9"].v + ">";
         tempText = tempText + (sheet['F'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["F9"].v + ">\r\n";
      }
      j++;
   }
   tempText = tempText + "\t\t" + '</MAIN>' + "\r\n";
   tempText = tempText + "\t" + '</AFRD_B>';
   return tempText;
}

function AFRD06() {
   var tempText, j, EndHere = 0;
   tempText = "\t" + '<AFRD_B1>' + "\r\n";
   j = 20;
   while (EndHere < 10) {
      if (sheet['A'+j] === undefined) {
         j++;
         EndHere = EndHere + 1;
      }
      else {
         EndHere = 0;
         switch(sheet['A'+j].v) {
            case "A.Investments in Debt Securities Issued by Development Bank of the Philippines (DBP)/Land Bank of the Philippines (LBP) 2/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y1>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y1_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y1_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y1>' + "\r\n";
               break;
            case "B.Investments in Sustainable Finance Instruments 3/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y2>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y2_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y2_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y2>' + "\r\n";
               break;
            case "C.Investments in Unsecured Subordinated Debt Securities Issued by Banks that are Rural Financial Institutions 4/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y3>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y3_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y3_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y3>' + "\r\n";
               break;
            case "D.Investments in Debt Securities of Micro, Small and Medium Enterprises (MSMEs) 5/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y4>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y4_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y4_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y4>' + "\r\n";
               break;
            case "E.Investments in Other Debt Securities 6/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y5>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  if (typeof sheet['E'+j].v !== 'number') {j++; continue;}
                  tempText = tempText + "\t\t\t" + '<MAIN_Y5_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y5_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y5>' + "\r\n";
               break;
            default:
            // code block
         }
      }
   }
   tempText = tempText + "\t" + '</AFRD_B1>';
   return tempText;
}

function AFRD07() {
   var tempText, j, EndHere = 0;
   tempText = "\t" + '<AFRD_B2>' + "\r\n";
   tempText = tempText + "\t\t" + '<MAIN>' + "\r\n";
   if (sheet['D11'] !== undefined) {
      tempText = tempText + "\t\t\t<" + sheet['C11'].v + sheet["D9"].v + ">";
      tempText = tempText + sheet['D11'].v;
      tempText = tempText + "</" + sheet['C11'].v + sheet["D9"].v + ">\r\n";
   }
   if (sheet['F11'] !== undefined) {
      tempText = tempText + "\t\t\t<" + sheet['C11'].v + sheet["F9"].v + ">";
      tempText = tempText + sheet['F11'].v;
      tempText = tempText + "</" + sheet['C11'].v + sheet["F9"].v + ">\r\n";
   }
   if (sheet['G11'] !== undefined) {
      tempText = tempText + "\t\t\t<" + sheet['C11'].v + sheet["G9"].v + ">";
      tempText = tempText + (sheet['G11'].v).toFixed(2);
      tempText = tempText + "</" + sheet['C11'].v + sheet["G9"].v + ">\r\n";
   }
   if (sheet['H11'] !== undefined) {
      tempText = tempText + "\t\t\t<" + sheet['C11'].v + sheet["H9"].v + ">";
      tempText = tempText + (sheet['H11'].v).toFixed(2);
      tempText = tempText + "</" + sheet['C11'].v + sheet["H9"].v + ">\r\n";
   }
   if (sheet['I11'] !== undefined) {
      tempText = tempText + "\t\t\t<" + sheet['C11'].v + sheet["I9"].v + ">";
      tempText = tempText + sheet['I11'].v;
      tempText = tempText + "</" + sheet['C11'].v + sheet["I9"].v + ">\r\n";
   }
   if (sheet['J11'] !== undefined) {
      tempText = tempText + "\t\t\t<" + sheet['C11'].v + sheet["J9"].v + ">";
      tempText = tempText + (sheet['J11'].v).toFixed(2);
      tempText = tempText + "</" + sheet['C11'].v + sheet["J9"].v + ">\r\n";
   }
   if (sheet['K11'] !== undefined) {
      tempText = tempText + "\t\t\t<" + sheet['C11'].v + sheet["K9"].v + ">";
      tempText = tempText + (sheet['K11'].v).toFixed(2);
      tempText = tempText + "</" + sheet['C11'].v + sheet["K9"].v + ">\r\n";
   }
   tempText = tempText + "\t\t" + '</MAIN>' + "\r\n";
   j = 20;
   while (EndHere < 10) {
      if (sheet['A'+j] === undefined) {
         j++;
         EndHere = EndHere + 1;
      }
      else {
         EndHere = 0;
         switch(sheet['A'+j].v) {
            case "A. Rural Financial Institutions":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y1>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y1_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  if (sheet['I'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["I9"].v + ">";
                     tempText = tempText + sheet['I'+j].v;
                     tempText = tempText + "</" + sheet["I9"].v + ">\r\n";
                  }
                  if (sheet['J'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["J9"].v + ">";
                     tempText = tempText + (sheet['J'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["J9"].v + ">\r\n";
                  }
                  if (sheet['K'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["K9"].v + ">";
                     tempText = tempText + (sheet['K'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["K9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y1_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y1>' + "\r\n";
               break;
            case "C. Companies Primarily Engaged in AFRD-related Activities, including Venture Capital Corporations":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y2>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y2_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  if (sheet['I'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["I9"].v + ">";
                     tempText = tempText + sheet['I'+j].v;
                     tempText = tempText + "</" + sheet["I9"].v + ">\r\n";
                  }
                  if (sheet['J'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["J9"].v + ">";
                     tempText = tempText + (sheet['J'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["J9"].v + ">\r\n";
                  }
                  if (sheet['K'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["K9"].v + ">";
                     tempText = tempText + (sheet['K'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["K9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t" + '</MAIN_Y2_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t\t" + '</MAIN_Y2>' + "\r\n";
               break;
            case "D. Micro, Small and Medium Enterprises (MSMEs) 2/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y3>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y3_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  if (sheet['I'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["I9"].v + ">";
                     tempText = tempText + sheet['I'+j].v;
                     tempText = tempText + "</" + sheet["I9"].v + ">\r\n";
                  }
                  if (sheet['J'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["J9"].v + ">";
                     tempText = tempText + (sheet['J'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["J9"].v + ">\r\n";
                  }
                  if (sheet['K'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["K9"].v + ">";
                     tempText = tempText + (sheet['K'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["K9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y3_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y3>' + "\r\n";
               break;
            case "E. Others 3/":
               j = j + 4;
               tempText = tempText + "\t\t" + '<MAIN_Y4>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  if (typeof sheet['E'+j].v !== 'number') {j++; continue;}
                  tempText = tempText + "\t\t\t" + '<MAIN_Y4_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D9"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D9"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F9"].v + ">";
                     tempText = tempText + sheet['F'+j].v;
                     tempText = tempText + "</" + sheet["F9"].v + ">\r\n";
                  }
                  if (sheet['G'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["G9"].v + ">";
                     tempText = tempText + (sheet['G'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["G9"].v + ">\r\n";
                  }
                  if (sheet['H'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["H9"].v + ">";
                     tempText = tempText + (sheet['H'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["H9"].v + ">\r\n";
                  }
                  if (sheet['I'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["I9"].v + ">";
                     tempText = tempText + sheet['I'+j].v;
                     tempText = tempText + "</" + sheet["I9"].v + ">\r\n";
                  }
                  if (sheet['J'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["J9"].v + ">";
                     tempText = tempText + (sheet['J'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["J9"].v + ">\r\n";
                  }
                  if (sheet['K'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["K9"].v + ">";
                     tempText = tempText + (sheet['K'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["K9"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y4_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y4>' + "\r\n";
               break;
            default:
            // code block
         }
      }
   }
   tempText = tempText + "\t" + '</AFRD_B2>';
   return tempText;
}

function AFRD08() {
   var tempText, j, EndHere = 0;
   tempText = "\t" + '<AFRD_B3>' + "\r\n";
   j = 15;
   while (EndHere < 10) {
      if (sheet['A'+j] === undefined) {
         j++;
         EndHere = EndHere + 1;
      }
      else {
         EndHere = 0;
         switch(sheet['A'+j].v) {
            case "A. Placements in Deposit Accounts, other than Fixed Term Deposit Products":
               j = j + 3;
               tempText = tempText + "\t\t" + '<MAIN_Y1>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y1_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D8"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D8"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F8"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F8"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y1_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y1>' + "\r\n";
               break;
            case "B. Placements in Fixed Term Deposit Products":
               j = j + 3;
               tempText = tempText + "\t\t" + '<MAIN_Y2>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  if (typeof sheet['E'+j].v !== 'number') {j++; continue;}
                  tempText = tempText + "\t\t\t" + '<MAIN_Y2_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D8"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D8"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F8"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F8"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y2_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y2>' + "\r\n";
               break;
            default:
            // code block
         }
      }
   }
   tempText = tempText + "\t" + '</AFRD_B3>';
   return tempText;
}

function AFRD09() {
   var tempText, j, k;
   j = 12;
   k = 20;
   tempText = "\t" + '<AFRD_C>' + "\r\n";
   tempText = tempText + "\t\t" + '<MAIN>' + "\r\n";
   while (j <= k) {
      if (j === 17) {j++; continue;}
      if (sheet['D'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["D10"].v + ">";
         tempText = tempText + (sheet['D'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["D10"].v + ">\r\n";
      }
      if (sheet['E'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["E10"].v + ">";
         tempText = tempText + (sheet['E'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["E10"].v + ">\r\n";
      }
      if (sheet['F'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["F10"].v + ">";
         tempText = tempText + (sheet['F'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["F10"].v + ">\r\n";
      }

      if (sheet['G'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["G10"].v + ">";
         tempText = tempText + (sheet['G'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["G10"].v + ">\r\n";
      }
      if (sheet['H'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["H10"].v + ">";
         tempText = tempText + (sheet['H'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["H10"].v + ">\r\n";
      }
      if (sheet['I'+j] !== undefined) {
         tempText = tempText + "\t\t\t<" + sheet['C'+j].v + sheet["I10"].v + ">";
         tempText = tempText + (sheet['I'+j].v).toFixed(2);
         tempText = tempText + "</" + sheet['C'+j].v + sheet["I10"].v + ">\r\n";
      }

      j++;
   }
   tempText = tempText + "\t\t" + '</MAIN>' + "\r\n";
   tempText = tempText + "\t" + '</AFRD_C>';
   return tempText;
}
function AFRD10() {
   var tempText, j, EndHere = 0;
   tempText = "\t" + '<AFRD_C1>' + "\r\n";
   j = 14;
   while (EndHere < 10) {
      if (sheet['A'+j] === undefined) {
         j++;
         EndHere = EndHere + 1;
      }
      else {
         EndHere = 0;
         switch(sheet['A'+j].v) {
            case "A. Wholesale Lending Facility":
               j = j + 3;
               tempText = tempText + "\t\t" + '<MAIN_Y1>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  tempText = tempText + "\t\t\t" + '<MAIN_Y1_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D8"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D8"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F8"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F8"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y1_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y1>' + "\r\n";
               break;
            case "B. Rediscounting Facility":
               j = j + 3;
               tempText = tempText + "\t\t" + '<MAIN_Y2>' + "\r\n";
               while (sheet['E'+j] !== undefined) {
                  if (typeof sheet['E'+j].v !== 'number') {j++; continue;}
                  tempText = tempText + "\t\t\t" + '<MAIN_Y2_Item>' + "\r\n";
                  if (sheet['D'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["D8"].v + ">";
                     tempText = tempText + (sheet['D'+j].v).replace(/&/g, "&amp;");
                     tempText = tempText + "</" + sheet["D8"].v + ">\r\n";
                  }
                  if (sheet['F'+j] !== undefined) {
                     tempText = tempText + "\t\t\t\t<" + sheet["F8"].v + ">";
                     tempText = tempText + (sheet['F'+j].v).toFixed(2);
                     tempText = tempText + "</" + sheet["F8"].v + ">\r\n";
                  }
                  tempText = tempText + "\t\t\t" + '</MAIN_Y2_Item>' + "\r\n";
                  j++;
               }
               tempText = tempText + "\t\t" + '</MAIN_Y2>' + "\r\n";
               break;
            default:
            // code block
         }
      }
   }
   tempText = tempText + "\t" + '</AFRD_C1>';
   return tempText;
}

function AFRD11() {
   var tempText, j, k, n, subsidiaries = new Array(), SubdItem;
   subsidiaries = (document.getElementById('Subsidiaries').value).split(",");
   const alphabets = ["D", "E"];
   tempText = "\t" + '<AFRD_D>' + "\r\n";
   tempText = tempText + "\t\t" + '<MAIN>' + "\r\n";
   if (sheet['D21'] !== undefined) {
      tempText = tempText + "\t\t\t<" + sheet['C21'].v + sheet["D9"].v + ">";
      tempText = tempText + (sheet['D21'].v).toFixed(2);
      tempText = tempText + "</" + sheet['C21'].v + sheet["D9"].v + ">\r\n";
   }
   tempText = tempText + "\t\t" + '</MAIN>' + "\r\n";
   tempText = tempText + "\t\t" + '<MAIN_X1>' + "\r\n";
   for (n = 0; n < alphabets.length; n++){
      j = 32;
      k = 43;
      tempText = tempText + '\t\t\t<MAIN_X1_Item>' + "\r\n";
      tempText = tempText + "\t\t\t\t<CODE>" + subsidiaries[n].trim() + "</CODE>"  + "\r\n";
      while (j <= k) {
         if (j === 33) {j++; continue;}
         if (j === 35) {j++; continue;}
         if (j === 38) {j++; continue;}
         if (j === 42) {j++; continue;}
         SubdItem = alphabets[n] + j;
         if (sheet[SubdItem] !== undefined) tempText = tempText + "\t\t\t\t<" + sheet['C'+j].v + ">" + (sheet[SubdItem].v).toFixed(2)  + "</" + sheet['C'+j].v + ">" + "\r\n";
         j++;
      }
      tempText = tempText + "\t\t\t" + '</MAIN_X1_Item>' + "\r\n";
   }
   tempText = tempText + "\t\t" + '</MAIN_X1>' + "\r\n";
   tempText = tempText + "\t" + '</AFRD_D>';
   return tempText;
}
